<template>
  <div class="mt-4">
    <v-data-table
      :headers="headers"
      dense
      :items-per-page="-1"
      hide-default-footer
      :items="orderedListagem"
      :item-class="itemRowClass"
      class="elevation-1 small"
    >
      <template v-slot:[`item.placa`]="{ item }">
        <div class="w-100 d-flex justify-center my-2" v-if="item.placa">
          <v-chip small color="primary">{{ item.placa }}</v-chip>
        </div>
      </template>
      <template v-slot:[`item.peso_balanca`]="{ item }">
        <div class="w-100 d-flex justify-center">
          <v-chip small color="primary">{{
            item.peso_balanca | mask_peso
          }}</v-chip>
        </div>
      </template>
      <template v-slot:[`item.sentido`]="{ item }">
        <div class="w-100 d-flex justify-center my-2">
          <v-icon color="green">
            {{ item.sentido ? 'mdi-arrow-up-circle' : 'mdi-arrow-down-circle' }}
          </v-icon>
        </div>
      </template>
      <template v-slot:[`item.lado_a`]="{ item }">
        <div class="d-flex flex-row">
          <div v-if="'cancela' in item.lado_a">
            <v-icon :color="item.lado_a.cancela ? 'green' : 'red'">
              {{ item.lado_a.cancela ? 'mdi-boom-gate-up' : 'mdi-boom-gate' }}
            </v-icon>
          </div>
          <div v-if="'sensores' in item.lado_a">
            <v-tooltip
              bottom
              v-for="sensor in item.lado_a.sensores"
              v-bind:key="sensor.label"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="sensor.status ? 'green' : 'red'"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-circle
                </v-icon>
              </template>
              <span>{{ sensor.label }}</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:[`item.lado_b`]="{ item }">
        <div class="d-flex flex-row">
          <div v-if="'cancela' in item.lado_b">
            <v-icon :color="item.lado_b.cancela ? 'green' : 'red'">
              {{ item.lado_b.cancela ? 'mdi-boom-gate-up' : 'mdi-boom-gate' }}
            </v-icon>
          </div>
          <div v-if="'sensores' in item.lado_b">
            <v-tooltip
              bottom
              v-for="sensor in item.lado_b.sensores"
              v-bind:key="sensor.label"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="sensor.status ? 'green' : 'red'"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-circle
                </v-icon>
              </template>
              <span>{{ sensor.label }}</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:[`item.cameras`]="{ item }">
        <div class="d-flex flex-row">
          <div v-if="'cameras' in item">
            <v-tooltip
              bottom
              v-for="camera in item.cameras"
              v-bind:key="camera.label"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="camera.status ? 'green' : 'red'"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ camera.status ? 'mdi-camera' : 'mdi-camera-off' }}
                </v-icon>
              </template>
              <span>{{ camera.label }}</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="mr-2 mb-2"
              dark
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item class="pointer" @click="ocrManual({ item: item })"
              >OCR Manual</v-list-item
            >
            <v-list-item
              class="pointer"
              @click="comando_cancela_a({ item: item, tipo: 0 })"
              >Abrir/Fechar cancela A</v-list-item
            >
            <v-list-item
              class="pointer"
              @click="comando_cancela_b({ item: item, tipo: 1 })"
              >Abrir/Fechar cancela B</v-list-item
            >
            <v-list-item class="pointer" @click="reset({ item: item })"
              >Reset</v-list-item
            >
            <v-list-item
              class="pointer"
              v-if="item.alerta"
              @click="retornar({ item: item })"
              >Retornar</v-list-item
            >
            <v-list-item
              class="pointer"
              v-if="item.alerta"
              @click="repesar({ item: item })"
              >Repesar</v-list-item
            >
            <v-list-item
              class="pointer"
              v-if="item.alerta"
              @click="confirmar({ item: item })"
              >Confirmar</v-list-item
            >
            <v-list-item
              class="pointer"
              v-if="item.agendamento"
              @click="openDialogPesagemHistory({ public_id: item.agendamento })"
              >Histórico de pesagens</v-list-item
            >
          </v-list>
        </v-menu>
      </template>
      <template #no-data v-if="!canList">
        <span class="red--text"
          >Você não tem permissão para visualizar as informações desta
          tela</span
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { props } from './_props'
import { data } from './_data'
import { computed } from './_computed'
import { methods } from './_methods'
import {
  dateAgendamento,
  date2br,
  iso2br,
  toBrazilianDateTime,
} from '@/mixins/convertion'
import {
  cota_dia_todo,
  cota_mesmo_dia,
  mostra_cota_mesmo_dia,
} from '@/mixins/cotas.js'
import { Pesagem } from '@/utils/pesagem'

export default {
  inject: {
    pesar: {
      from: 'pesar',
      default: () => {},
    },
    retornar: {
      from: 'retornar',
      default: () => {},
    },
    repesar: {
      from: 'repesar',
      default: () => {},
    },
    reset: {
      from: 'reset',
      default: () => {},
    },
    confirmar: {
      from: 'confirmar',
      default: () => {},
    },

    comando_cancela_a: {
      from: 'comando_cancela_a',
      default: () => {},
    },
    comando_cancela_b: {
      from: 'comando_cancela_b',
      default: () => {},
    },
    openDialogPesagemHistory: {
      from: 'openDialogPesagemHistory',
      default: () => {},
    },
    ocrManual: {
      from: 'ocrManual',
      default: () => {},
    },
  },
  mixins: [
    dateAgendamento,
    date2br,
    cota_dia_todo,
    cota_mesmo_dia,
    mostra_cota_mesmo_dia,
  ],
  props,
  components: {},
  created() {
    this.PESAGEM = Pesagem
  },
  data,
  computed,
  methods,
  watch: {
    'empresaAtual.public_id': {
      handler() {
        this.$emit('cleanData')
      },
    },
  },
  filters: {
    iso2br,
    toBrazilianDateTime,
    mask_peso(quantidade) {
      if (!quantidade) return '0'

      return quantidade.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
    },
  },
}
</script>
<style>
.small tbody tr td {
  /* font-size: smaller !important; */
  font-size: x-small !important;
}

.style-1 {
  background-color: white;
}

.style-2 {
  background-color: #00aa9e;
  /* background-color: #23b14d; */
  color: white;
}
.style-3 {
  /* background-color: #f34848; */
  color: #f34848;
}
/* .style-3:hover { */
/* color: black !important; */
/* } */
</style>
<style lang="scss" scoped>
.status--cancelado {
  padding: 10px 15px;
  background: rgb(243, 72, 72);
  border-radius: 10px;
  color: white;
}

.status--triagem {
  padding: 10px 15px;
  background: rgb(64, 228, 64);
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

.button-submit-orange {
  background: red;
}

.div-pedido-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.div-pedido-info p {
  margin: 5px 0;
}

div {
  margin-bottom: 5px;
}

p,
label {
  font: 1rem 'Fira Sans', sans-serif;
  text-transform: uppercase;
  margin-left: 8px;
}

input {
  min-width: 20px;
  min-height: 20px;
  margin-right: 5px;
}

legend {
  margin-bottom: 20px;
}
</style>
