var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"elevation-1 small",attrs:{"headers":_vm.headers,"dense":"","items-per-page":-1,"hide-default-footer":"","items":_vm.orderedListagem,"item-class":_vm.itemRowClass},scopedSlots:_vm._u([{key:"item.placa",fn:function(ref){
var item = ref.item;
return [(item.placa)?_c('div',{staticClass:"w-100 d-flex justify-center my-2"},[_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(item.placa))])],1):_vm._e()]}},{key:"item.peso_balanca",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 d-flex justify-center"},[_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(_vm._f("mask_peso")(item.peso_balanca)))])],1)]}},{key:"item.sentido",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 d-flex justify-center my-2"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" "+_vm._s(item.sentido ? 'mdi-arrow-up-circle' : 'mdi-arrow-down-circle')+" ")])],1)]}},{key:"item.lado_a",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[('cancela' in item.lado_a)?_c('div',[_c('v-icon',{attrs:{"color":item.lado_a.cancela ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.lado_a.cancela ? 'mdi-boom-gate-up' : 'mdi-boom-gate')+" ")])],1):_vm._e(),('sensores' in item.lado_a)?_c('div',_vm._l((item.lado_a.sensores),function(sensor){return _c('v-tooltip',{key:sensor.label,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":sensor.status ? 'green' : 'red'}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(sensor.label))])])}),1):_vm._e()])]}},{key:"item.lado_b",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[('cancela' in item.lado_b)?_c('div',[_c('v-icon',{attrs:{"color":item.lado_b.cancela ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.lado_b.cancela ? 'mdi-boom-gate-up' : 'mdi-boom-gate')+" ")])],1):_vm._e(),('sensores' in item.lado_b)?_c('div',_vm._l((item.lado_b.sensores),function(sensor){return _c('v-tooltip',{key:sensor.label,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":sensor.status ? 'green' : 'red'}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(sensor.label))])])}),1):_vm._e()])]}},{key:"item.cameras",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[('cameras' in item)?_c('div',_vm._l((item.cameras),function(camera){return _c('v-tooltip',{key:camera.label,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":camera.status ? 'green' : 'red'}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(camera.status ? 'mdi-camera' : 'mdi-camera-off')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(camera.label))])])}),1):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 mb-2",attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.ocrManual({ item: item })}}},[_vm._v("OCR Manual")]),_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.comando_cancela_a({ item: item, tipo: 0 })}}},[_vm._v("Abrir/Fechar cancela A")]),_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.comando_cancela_b({ item: item, tipo: 1 })}}},[_vm._v("Abrir/Fechar cancela B")]),_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.reset({ item: item })}}},[_vm._v("Reset")]),(item.alerta)?_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.retornar({ item: item })}}},[_vm._v("Retornar")]):_vm._e(),(item.alerta)?_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.repesar({ item: item })}}},[_vm._v("Repesar")]):_vm._e(),(item.alerta)?_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.confirmar({ item: item })}}},[_vm._v("Confirmar")]):_vm._e(),(item.agendamento)?_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.openDialogPesagemHistory({ public_id: item.agendamento })}}},[_vm._v("Histórico de pesagens")]):_vm._e()],1)],1)]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }